(function ($, window) {
  if ($.isFunction(window.magnificPopup)) {
    $('.mag-pop-up').magnificPopup({
      type: 'iframe',
    });
  }


  // Contact form is ready to submit
  jQuery(function ($) {

    $(".contact-form :required").on('blur', function (e) {
      var $this = $(this);
      if (this.checkValidity()) {
        $this.addClass('is-invalid');
      } else {
        $this.removeClass('is-valid');
      }

      if ($this.val() === '') {
        $this.addClass('is-invalid');
        $this.prev('label').addClass('error');
      } else {
        $this.removeClass('is-invalid');
        $this.prev('label').removeClass('error');
      }

      let $els = $('.contact-form :required');

      let isValid = true;
      $els.toArray().every(function (el) {
        if (el.value == '') {
          isValid = false;
          return false;
        }

        if (el.type === 'email' && !el.checkValidity()) {
          isValid = false;
          return false;
        }
      });
      // Enable submit button if all required fields are filled
      $(".contact-form [type='submit']").prop('disabled', !isValid);

    });


    $(".contact-form [type='submit']").on('submit', function (e) {

    });
  });
}(jQuery, window));

